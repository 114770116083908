import CartDrawer from './common/cartDrawer';
import Footer from './common/footer';
import Header from './common/header';
import MenuMobile from './common/menuMobile';
import Popup from './components/popup';
import Webforms from './common/webforms';
import ProductPurchase from './components/productPurchase';
import { NewsletterComponent, setFidelizarMais, setPrice } from './components/vndaComponents.js';

const page = document.querySelector('body').getAttribute('data-page');

// ==========================================
// Inicialização
// ==========================================
console.log('%cVnda - Tecnologia em Ecommerce', 'color: #f88d5b; font-size: 15px; font-family: "Verdana", sans-serif; font-weight: bold;');

window.addEventListener('DOMContentLoaded', () => {
  CartDrawer.init();
  Header.init();
  Footer.init();
  Popup.init();
  ProductPurchase.init();
  Webforms.init();
  
  if (window.innerWidth < 1025) MenuMobile.init();
  
  NewsletterComponent.init();
  setPrice();
  setFidelizarMais();
});
