import { addAsset } from "./utilities";

// ===============================================================
// NEWSLETTER POPUP
// ===============================================================
export const NewsletterComponent = {
  root: document.querySelector('#component-popup-newsletter-root'),
  settings: window.popupNewsletterSettings || false,
  loaded: false,

  setPopupNewsletter: function() {

    const { settings, root } = NewsletterComponent

    // Define propriedades do componente
    let maxWidth = settings.maxWidth;
    let imageUrl = settings.imageUrl;
    let title = settings.title;
    let description = settings.description;
    let subdomain = settings.subdomain;
    let successMessage = settings.success;

    // Inicia o componente
    const componentNewsletterPopup = new Vnda.Component.NewsletterPopup({
      maxWidth: maxWidth,
      maxHeight: 500,
      imageUrl: imageUrl,
      imagePosition: 'left',
      popupPosition: 'center',
      title: title,
      description: description,
      textBtnSubmit: 'Enviar',
      classBtnSubmit: 'button-newsletter',
      formKey: `${subdomain}-newsletter`,
      hasNameField: false,
      hasLastNameField: false,
      hasDateOfBirthField: false,
      hasPhoneField: false,
      successMessage: successMessage,
      delay: 500,
      frequency: '7',
      language: 'pt-BR',
    });

    // Renderiza o componente e marca como carregado
    componentNewsletterPopup.render(root);
    NewsletterComponent.loaded = true
  },

  loadPopupNewsletter: function() {
    if (!NewsletterComponent.loaded) {
      const { settings } = NewsletterComponent;
      addAsset(settings.script, NewsletterComponent.setPopupNewsletter)
      addAsset(settings.styles)
    }
  },

  init: function() {
    const { root, settings } = this;

    if (!root || !settings) return;

    const eventType = (window.innerWidth <= 1024) ? 'scroll' : 'mousemove'
    window.addEventListener(eventType, () => {
      NewsletterComponent.loadPopupNewsletter()
    }, { once: true })
  }
}

// ===============================================================
// FILTROS
// ===============================================================
export function setFilters() {
  var componentFilters;
  var properties = [];

  if (!window.aggregations) return;

  // Seleciona o elemento
  var root = document.querySelector('#component-products-filter-root');
  var openMobile = document.getElementById('open-filter-mobile');

  // Define filtros de categoria
  let tags = aggregations.types.categoria && [
    {
      title: 'Categoria',
      type: 'categoria',
      style: 'list',
      options: aggregations.types.categoria,
    },
  ];

  // Define filtros do primeiro atributo
  let property1 = aggregations.properties.property1.length > 0 && {
    title: 'Tamanho',
    property: 'property1',
    style: 'list',
    options: aggregations.properties.property1,
  };

  if (property1) {
    properties.push(property1);
  }

  // Define filtros do segundo atributo
  let property2 = aggregations.properties.property2.length > 0 && {
    title: 'Cor',
    property: 'property2',
    style: 'colors',
    colorsProps: {
      showTitle: true,
      showColor: true,
    },
    options: aggregations.properties.property2,
  };

  if (property2) {
    properties.push(property2);
  }

  // Define filtros do terceiro atributo
  let property3 = aggregations.properties.property3.length > 0 && {
    title: 'Fragrância',
    property: 'property3',
    style: 'list',
    options: aggregations.properties.property3,
  };

  if (property3) {
    properties.push(property3);
  }

  // Define os ranges dos preçõs
  if (filterRange.length > 0) {
    const convertedPrices = filterRange.map((range) => {
      var itens = [];
      range.map((item) => {
        itens.push(parseFloat(item));
      });
      return itens;
    });

    filterRange = convertedPrices.sort((a, b) => a[0] - b[0]);
  } else {
    filterRange = [
      [1, 99.99],
      [100, 199.99],
      [200, 399.99],
      [300, 399.99],
      [400, 499.99],
    ];
  }

  // Inicia o componente
  componentFilters = new Vnda.Component.ProductsFilter({
    mode: 'drawer',
    hasSort: true,
    filterOnClick: false,
    resetMode: 'all',
    // tags type "categoria"
    tags: tags,
    // properties
    properties: properties,
    // price
    price: [aggregations.min_price, aggregations.max_price],
    priceProps: {
      mode: 'options',
      options: filterRange,
    },
  });

  // Renderiza o
  componentFilters.render(root);

  // atualiza botão de abrir drawer
  openMobile.addEventListener('click', componentFilters.toggle);
}

// ===============================================================
// LOGIN
// ===============================================================
export function setLogin() {
  // Seleciona o elemento
  const root = document.querySelector('#component-login-static-root');

  // Define link para o Facebook
  let facebook_connect_url = root.getAttribute('data-facebook');

  // Inicia o componente
  window.componentLogin = {};
  window.componentLogin['static'] = new Vnda.Component.Login({
    mode: 'static',
    formProps: {
      login: {
        buttons: {
          facebook: {
            link: facebook_connect_url,
          },
        },
        hasFacebook: false,
      },
      register: {
        buttons: {
          facebook: {
            link: facebook_connect_url,
          },
        },
        hasFacebook: false,
      },
    },
  });

  // Renderiza o componente
  componentLogin['static'].render(root);
}

// ===============================================================
// PREÇO
// ===============================================================
export function setPrice() {
  const script = window.priceComponent || false;
  if (!script) return;

  const firstPriceEl = document.querySelector('[data-init-price]');
  if (!firstPriceEl) return;

  const observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting) {
      console.log('Price detected! Script added.');
      
      // Cria arquivo de script
      let newScript = document.createElement('script');
      newScript.setAttribute('src', script);
      document.body.appendChild(newScript);

      observer.disconnect()
    }
  }, { threshold: 0.1 });
  
  observer.observe(firstPriceEl);
}

// ===============================================================
// WIDGET FIDELIZAR MAIS
// ===============================================================
export function setFidelizarMais() {
  const setWidget = () => {
    window.removeEventListener('mousemove', setWidget);
    window.removeEventListener('scroll', setWidget);

    let newScript = document.createElement('script');
    newScript.setAttribute('src', 'https://cdns.fidelizarmais.com/files/production/7e1b63e4-b5d6-4ace-8b56-2dd542ffc2e5/d0c1a2c9-3d5f-47d2-909f-e9d71ffed491/fidelidade/v2/js/sdk-basic.js?ver=638122459970855314');
    newScript.setAttribute('data-widget-host', 'https://widget.fidelizarmais.com');
    newScript.setAttribute('data-partner', 'ZDBjMWEyYzktM2Q1Zi00N2QyLTkwOWYtZTlkNzFmZmVkNDkx');
    newScript.setAttribute('data-token', 'N2UxYjYzZTQtYjVkNi00YWNlLThiNTYtMmRkNTQyZmZjMmU1');
    newScript.setAttribute('id', 'jswidgettradeidsdk');
    newScript.setAttribute('async', 'true');
    document.body.appendChild(newScript);
  }
  window.addEventListener('mousemove', setWidget);
  window.addEventListener('scroll', setWidget);
}
