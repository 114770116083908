const HeaderCart = {
  buttons: document.querySelectorAll('[data-toggle-cart]'),
  countWrapper: document.querySelector('[data-cart-count]'),
  root: document.getElementById("component-cart-drawer-root"),
  selector: '[data-cart-count]',
  attr: '[data-cart-count-attr]',
  text: '[data-cart-item-text]',
  useCustomCart: true,
  //useCustomCart: document.documentElement.lang === 'pt-BR' ? false : true,

  getCartItens: async function () {
    try {
      const response = await fetch('/carrinho/itens');
      const itens = await response.json()
      return itens;

    } catch (error) {
      console.error('Erro ao buscar a quantidade de produtos do carrinho');
      console.error(error);
    }
  },

  updateCartCount: async function (_itemsCount = null) {
    let items = _itemsCount;
    if (_itemsCount == null) items = await this.getCartItens();
    this.countWrapper.innerHTML = items;
  },

  handleOpen: function () {
    const buttons = document.querySelectorAll('[data-action="open-cart"]');
    buttons.forEach(button => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        window.componentCartDrawer.open();

        //fecha o menu mobile se clicar no botão de carrinho do mmenu
        if (button.classList.contains('-mobile')) {
          window.mmenu.close();
        }
      });
    });
  },

  show: function (update = false) {

    const { useCustomCart } = this;

    if (useCustomCart) {
      if (update) HeaderCart.customCart.loadCart()
      return HeaderCart.customCart.show()

    } else {

      window.componentCartDrawer.open();

      $.ajax({
        url: "/carrinho",
        type: "GET",
        dataType: "json",
      }).done(function (response) {
        console.log(response.coupon_code);
      });
    }
  },

  setCartDrawer: function() {
    // Define frete grátis
    const free_shipping_value = HeaderCart.root.getAttribute('data-free');

    // Inicia o componente
    const interval = setInterval(() => {
      if (Vnda) {
        if (Vnda.Component) {
          if (Vnda.Component.CartDrawer) {

            const componentCartDrawer = new Vnda.Component.CartDrawer({
              anchor: 'right',
              display: 'list',
              startOpen: false,
              titleCart: 'Carrinho de compras',
              disableShippingCalculation: false,
              freeShipping: free_shipping_value,
            });
        
            // Renderiza o componente
            componentCartDrawer.render(HeaderCart.root);
        
            // Adiciona o componente ao escopo global
            window.componentCartDrawer = componentCartDrawer;
            HeaderCart.handleOpen();
            clearInterval(interval)
          }
        }
      }

    }, 2000)
    
  },

  handleCoupons: function () {
    // Observa aparição dos trechos onde consta a aplicação do cupom
    var newObserver = new MutationObserver(mutation => {
      
      //Captura elemento com a informação de cupom aplicado
      document.querySelectorAll("p.cart-drawer-text-sm.mb-3 strong.cart-drawer-font-semibold").forEach( element => {

        // Cupom aplicado, realizado os tratamentos
        if (element.innerText == 'Cupom adicionado:') {
          const buttonEditCoupon = element.parentElement.nextElementSibling;

          if (buttonEditCoupon && buttonEditCoupon.innerText == 'editar') {
            const formContainer = buttonEditCoupon.parentElement;
            // Altera texto do botão
            buttonEditCoupon.innerText = 'editar / remover';            
            buttonEditCoupon.addEventListener("click", ()=> {

              setTimeout(() => {
                HeaderCart.addRemoveCouponButton(formContainer);
              }, 100)
            })
            
          } else if (buttonEditCoupon && buttonEditCoupon.innerText == 'editar / remover') {
            // Valida cupom aplicado
            $.ajax({
              url: "/carrinho",
              type: "GET",
              dataType: "json",
            }).done(function (response) {

              // Monta em tela os elementos como se não houvesse cupom aplicado
              if (response.coupon_code == null) {
                buttonEditCoupon.innerText = "+ Cupom de desconto";
                element.parentElement.style.display = "none";
              }
            });
          }
        }

      });

      document.querySelectorAll("button.cart-drawer-focusable.cart-drawer-underline").forEach(element => {

        if (element.innerText == "+ Adicionar cupom de desconto") {
          element.innerText = "+ Cupom de desconto";
          const buttonAddCoupon = element;
          const formContainer = buttonAddCoupon.parentElement;

          buttonAddCoupon.addEventListener("click", ()=> {
            setTimeout(() => {
              HeaderCart.addRemoveCouponButton(formContainer);
            }, 100)
          })
        }
      })
    });

    newObserver.observe(document.body, { childList: true, subtree: true });
  },

  addRemoveCouponButton: function (formContainer) {
    // Cria o botão de remoção e insere no formulário
    const form = formContainer.querySelector("form.cart-drawer-flex");

    if(!form.querySelector("#remove-coupon")) {
      const newButton = document.createElement("button");
      newButton.setAttribute('type', 'button');
      newButton.setAttribute('id', 'remove-coupon');
      newButton.setAttribute('class', 'cart-drawer-ml-2 cart-drawer-button cart-drawer-relative cart-drawer-uppercase cart-drawer-p-3 cart-drawer-font-semibold ');
      newButton.innerText = "limpar";
      form.appendChild(newButton);

      // Remove cupom
      newButton.addEventListener("click", () => {
        $.ajax({
          url: '/cupom/ajax',
          cache: false,
          type: 'POST',
          dataType: 'json',
          data: { code: '' },
        })
          .done(function (resp) {
            //Atualiza preços no cart drawer
            window.componentCartDrawer.close();
            window.componentCartDrawer.open();
          })
          .fail(function (error) {
            console.error(error);
          })
      })
    } 
    
  },

  customCart: {
    getCartData: function (callback) {
      $.ajax({
        url: "/carrinho",
        type: "GET",
        dataType: "json",
      }).done(function (response) {
        if (typeof callback == "function") {
          callback(response);
        }
      });
    },
    loadCart: function (_cart, stopCoupon) {
      var _this = this;
      var $boxCart = $("[data-cart-body]");

      if (_cart) {
        console.log(_cart.items_count);
        HeaderCart.updateCartCount(_cart.items_count);
      }

      vnda.loadCartPopup(function (html) {
        $boxCart.empty();
        $boxCart.append(html);
        // console.log(html);

        // Botões de fechar dentro do popup, que são recriados no dom
        document.querySelectorAll('[data-custom-toggle-cart]').forEach(button => {
          button.addEventListener('click', () => {
            document.querySelector("[data-cart-body]").classList.toggle("-visible");
            document.querySelector("body").classList.toggle("-no-scroll");
          })
        })
      });
    },
    show: function () {
      var $cartBody = $("[data-cart-body]");
      //var $cartHeader = $('.cart');

      $cartBody.addClass("-visible");
      document.querySelector("body").classList.add("-no-scroll");

      $("html,body").animate(
        { scrollTop: 0 },
        { duration: "250" }
      );

      $(".MuiScopedCssBaseline-root").addClass("hidden");
      $(".vndaReferralsInviteWidget").addClass("hidden");

      setTimeout(function () {
        $cartBody.removeClass("-visible");
        document.querySelector("body").classList.remove("-no-scroll");
        $(".MuiScopedCssBaseline-root").removeClass("hidden");
        $(".vndaReferralsInviteWidget").removeClass("hidden");
      }, 60000);
    },
    hide: function () {
      var $cartBody = $("[data-cart-body]");

      $cartBody.removeClass("-visible");
      document.querySelector("body").classList.remove("-no-scroll");

      $(".MuiScopedCssBaseline-root").removeClass("hidden");
      $(".vndaReferralsInviteWidget").removeClass("hidden");
      
    },
    delete: async function (element) {
      var $item = $(element).closest("[data-item-cart]");
      var itemId = $item.data("item-id");
      // console.info('deletando item');

      if (!$item[0].classList.contains('-removing')) {
        await $.ajax({
          url: "/carrinho",
          type: "POST",
          dataType: "json",
          data: {
            _method: "delete",
            item_id: itemId,
          },
          beforeSend: function () {
            $item[0].classList.add("updating");
          },
        })
        .done(function (_cart) {
          console.log("item updated");
          HeaderCart.customCart.loadCart(_cart);
        })
        .fail(function (error) {
          console.log("error while updating", error.responseText);
        })
        .always(function () {
          $item[0].classList.remove("updating");
        });
      }
    },
    updateItem: function (element, action) {
      if (action == "plus" || action == "minus") {
        const item = element.closest("[data-item-cart]");

        if (!item.classList.contains("updating")) {
          const quantity = Number(item.getAttribute("data-item-quantity"));
          const itemId = Number(item.getAttribute("data-item-id"));

          // console.log(`${itemId} - ${quantity}`);

          let newQuantity;
          if (action == "plus") newQuantity = quantity + 1;
          if (action == "minus" && quantity > 1) newQuantity = quantity - 1;

          if (newQuantity != quantity) {
            $.ajax({
              url: "/carrinho/adicionar",
              type: "POST",
              dataType: "json",
              data: {
                _method: "patch",
                item_id: itemId,
                quantity: newQuantity,
              },
              beforeSend: function () {
                item.classList.add("updating");
              },
            })
            .done(function (_cart) {
              console.log("item updated");
              HeaderCart.customCart.loadCart(_cart);
            })
            .fail(function (error) {
              console.log("error while updating", error.responseText);
            })
            .always(function () {
              item.classList.remove("updating");
            });
          }
        }
      }
    },
  },

  init: function() {
    const _this = this;
    const { useCustomCart } = _this;

    _this.updateCartCount();

    if (useCustomCart) {
      // carrinho personalizado

      // Abertura do carrinho
      if (_this.buttons.length > 0) {
        _this.buttons.forEach(button => {
          button.addEventListener('click', () => {
            document.querySelector("[data-cart-body]").classList.toggle("-visible");
            document.querySelector("body").classList.toggle("-no-scroll");
          })

          document.querySelectorAll('[data-custom-toggle-cart]').forEach(button => {
            button.addEventListener('click', () => {
              document.querySelector("[data-cart-body]").classList.toggle("-visible");
              document.querySelector("body").classList.toggle("-no-scroll");
            })
          })
        })

        window.cartItemUpdate = _this.customCart.updateItem
        window.cartItemDelete = _this.customCart.delete
      }

    } else {

      _this.setCartDrawer();
      _this.handleCoupons();

    }
  }
};

export default HeaderCart;
